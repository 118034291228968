<template>
<!-- zx 2021 6 9 -->
<div>
  <!-- <div class="program1" v-lazy:background-image="require('./mini_program_img/banner.png')"> -->
  <div class="program1">
     <div class="start">
       <img v-lazy='require("./mini_program_img/gG4ELeO1.webp")' class="start_img">
     </div>
   <div class="bannert">
    <div class="mini1">电商小程序</div>
    <div class="mini2">快速挖掘潜在顾客，提高用户活跃度
    </div>
</div>
  </div>
  <div class="program2">
    <div class="mini3">适用场景与案例</div>
    <div class="mini4">可以查看本产品在各个场景下的真实客户案例，可直接扫码体验案例</div>
    <div class="mini5">
     <div>
      <div class="img-1">
         <img
       v-lazy="require('./mini_program_img/GiDOWVVo.webp')"
       class="mini7">
      </div>
       <div class="mini15">旅行社版</div>
     </div>
     <div>
       <div class="img-1">
         <img 
       v-lazy="require('./mini_program_img/06gLsIR3.webp')"
        class="mini7 ml40">
      </div>
      
       <div class="mini15  ml40">民宿版</div>
     </div>
     <div>
       <div class="img-1">
         <img  
       v-lazy="require('./mini_program_img/Defos3qY.webp')"
       class="mini7 ml40">
      </div>
      
       <div class="mini15  ml40">景区版</div>
     </div>
     <div>
       <div class="img-1">
         <img
        v-lazy="require('./mini_program_img/CfSwigqm.webp')"
       class="mini7 ml40">
      </div>
      
       <div class="mini15  ml40">文化特色版</div>
     </div>
    </div>
  </div>
  <div class="program3">
    <div class="mini3">云市场提供完善保证</div>
    <div class="mini12 mt60">
      <div class="mini8">
        <div class="img-2">
          <img
         v-lazy="require('./mini_program_img/DG1p8Xet.webp')"
         class="mini9">
        </div>
          <div class="mini10">无需开发，立即上线</div>
        <div class="mini11">丰富的行业场景小程序模板，按需拖拽即可让您0研发成本开店</div>
      </div>
      <div class="mini8 ml30">
        <div class="img-2">
           <img
        v-lazy="require('./mini_program_img/ojt8FiC3.webp')"
         class="mini9">
        </div>


      
          <div class="mini10">智慧客服，全程服务</div>
        <div class="mini11">专业客服答疑，申请小程序、功能配置、店铺装修等全链路客服全程指导</div>
      </div>
      <div class="mini8 ml30">
        <div class="img-2">
         <img 
        v-lazy="require('./mini_program_img/FoRHSr4o.webp')"
         class="mini9">
        </div>


       
          <div class="mini10">0元试用，按需购买</div>
        <div class="mini11">中小微用户0压力试用产品功能，提供多重规格优质产品选项，按需下单</div>
      </div>
    </div>
    <div class="mini12 mt54">
      <div class="mini8">
        <div class="img-2">
           <img
         v-lazy="require('./mini_program_img/B9pVfrzL.webp')"
         class="mini9">
        </div>


      
          <div class="mini10">互相监督，严格品控</div>
        <div class="mini11">同平台入驻服务商共同监督，实现行业自律，保证入驻商品及服务品质</div>
      </div>
      <div class="mini8 ml30">
        <div class="img-2">
           <img 
        v-lazy="require('./mini_program_img/cHx8Irnj.webp')"
        class="mini9">
        </div>


      
          <div class="mini10">品质售后，不满退货</div>
        <div class="mini11">提供品质售后保障，购买过程及使用过程不满可依流程极速退货</div>
      </div>
      <div class="mini8 ml30">
        <div class="img-2">
          <img 
         v-lazy="require('./mini_program_img/ngVu8ExC.webp')"
         class="mini9">
        </div>


       
          <div class="mini10">平台担保，安全交易</div>
        <div class="mini11">全程担保交易，先验货后付款，保证用户资金100%安全</div>
      </div>
    </div>
  </div>
  <div class="program4">
    <div class="img-4">
      <img v-lazy="require('./mini_program_img/vfbgHGey.webp')" class="mini16">
    </div>
    <div class="mini17">
      <div>
      <div class="mini18">全程无忧服务，高效完成搭建</div>
       <div class="mini19 mt24"> 
        <div class="mini20"></div>
        <div class="mini21">沟通需求，体验产品</div>
      </div>
      <div class="mini19 mt16">
        <div class="mini20"></div>
        <div class="mini21">选择合适方案下单</div>
      </div>
      <div class="mini19 mt16">
        <div class="mini20"></div>
        <div class="mini21">为您注册微信小程序</div>
      </div>
      <div class="mini19 mt16">
        <div class="mini20"></div>
        <div class="mini21">沟通小程序装修制作</div>
      </div>
      <div class="mini19 mt16">
        <div class="mini20"></div>
        <div class="mini21">验收</div>
      </div>
      </div>
    </div>
  </div>

<div>
  <Header></Header>
  <Footer></Footer>
  </div>
</div>
</template>
<script>
//引入头部组件
import Header from "@/components/Header.vue";
// 引入底部组件
import Footer from "@/components/Footer.vue";
export default {
components: {
    Header,
    Footer,
  },
}
</script>
<style lang="scss" scoped>
/* 动画效果 */
@keyframes sport {
  0% {
    transform: translateY(80px);
    opacity: 1;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

 .program1{
  height: 600px;
  /* background-repeat:  no-repeat; */
  /* background-size: 100% 100%; */
 }
 .start_img{
   height: 600px;
  width: 100%;
}
.start{
   height: 600px;
  width: 100%;
  display: flex;
    justify-content: center;
    align-items: center;
    img[lazy="loading"] {
		width: 200px;
		height: 200px;
	}
	// 实际图片大小
	img[lazy="loaded"] {
		 height: 600px;
  width: 100%;
	}
}
.bannert{
   position: absolute;
    left: 361px;
    top: 230px;

}
 .mini1{
   color: rgba(255, 255, 255, 1);
  font-size: 48px;
  // padding-top: 230px;
  // padding-left: 360px;
  text-align: left;
 }
 .mini1{
    animation: sport 0.5s;
  transform: translateY(0px);
 }
 .mini2{
   color: rgba(255, 255, 255, 1);
  font-size: 24px;
  text-align: left;
  margin-top: 20px;
  // padding-left: 360px;
 }
 .mini2{
    animation: sport 0.5s;
  transform: translateY(0px);
 }
 .mini3{
    color: rgba(51, 51, 51, 1);
   font-size: 40px;
   padding-top: 80px;
 }
 .mini4{
  color: rgba(86, 86, 86, 1);
  font-size: 20px;
  margin-top: 24px;
 }
 .mini5{
   display: flex;
   justify-content: center;
   align-items: center;
   margin-top: 60px;
   margin-bottom: 100px;
 }
 .mini7{
  width: 268px;
   height: 374px;

 }
 .img-1{
   width: 268px;
   height: 374px;
   display: flex;
    justify-content: center;
    align-items: center;
    img[lazy="loading"] {
		width: 60px;
		height: 60px;
	}
	// 实际图片大小
	img[lazy="loaded"] {
		width: 268px;
   height: 374px;
	}
 }

 .mini6{
   width: 268px;
   height: 374px;
   border-radius: 10px;
  background-color: rgba(51, 51, 51, 1);
 }
 .program3{
   margin-top: 80px;
   padding-bottom: 100px;
   background-color: #F6F8FB;
 }
 .mini12{
   display: flex;
   justify-content: center;
 }
 .mt60{
   margin-top: 60px;
 }
 .mini9{
   width: 380px;
   height: 260px;
 }
 .img-2{
    width: 380px;
   height: 260px;
    display: flex;
    justify-content: center;
    align-items: center;
    img[lazy="loading"] {
		width: 50px;
		height: 50px;
	}
	// 实际图片大小
	img[lazy="loaded"] {
		 width: 380px;
   height: 260px;
	}
 }
 .mini8{
  height: 434px;
  border-radius: 6px;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 2px 4px 12px 2px rgba(0, 0, 0, 0.04);
  width: 380px;
 }
 .mini10{
   color: rgba(51, 51, 51, 1);
  font-size: 24px;
  text-align: left;
  margin-left: 24px;
  margin-top: 24px;
 }
 .mini11{
   color: rgba(153, 153, 153, 1);
  font-size: 20px;
  margin-top: 16px;
  margin-left: 24px;
  text-align: left;
 }
 .ml30{
   margin-left: 30px;
 }
 .mt54{
   margin-top: 54px;
 }
 .mini15{
   color: rgba(51, 51, 51, 1);
  font-size: 20px;
 }
 .program4{
   height: 545px;
   display: flex;
   justify-content: center;
   align-items: center;
 }
 .mini16{
   width: 496px;
   height: 365px;
 }
 .img-4{
     width: 496px;
   height: 365px;
    display: flex;
    justify-content: center;
    align-items: center;
    img[lazy="loading"] {
		width: 60px;
		height: 60px;
	}
	// 实际图片大小
	img[lazy="loaded"] {
		width: 496px;
   height: 365px;
	}
 }
 .mini17{
   text-align: left;
   width: 704px;
   height: 365px;
  border-radius: 0 0 6px 6px;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 2px 4px 12px 2px rgba(0, 0, 0, 0.04);
  display: flex;
  justify-content: center;
 }
 .mini18{
   color: rgba(51, 51, 51, 1);
  font-size: 32px;
  margin-top: 46px;
  width: 429px;
 }
 .mini19{
   display: flex;
   align-items: center;
 }
 .mini20{
    height: 8px;
  width: 8px;
  border-radius: 50%;
  background: #0963F9;
 }
 .mini21{
   color: rgba(153, 153, 153, 1);
  font-size: 20px;
  margin-left: 10px;
 }
 .mt24{
   margin-top: 24px;
 }
 .mt16{
   margin-top: 16px;
 }
 .ml40{
   margin-left: 40px;
 }
</style>